import PropTypes from 'prop-types';
import Manager from './manager';
import './character.scss';

const Character = ({page, deviceInfo, characterId, shouldFadeManager = false}) => {
	return (
		<div className={'Character ' + page + ' ' + characterId + ' ' + deviceInfo.orientation}>
			{characterId.includes('manager') &&
				<Manager
					page={page} 
					managerId={characterId} 
					deviceInfo={deviceInfo} 
					managerPose={characterId} 
					shouldFadeManager={shouldFadeManager}
				/>
			}
		</div>
	);
};

Character.propTypes = {
	page: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	characterId: PropTypes.string.isRequired,
	shouldFadeManager: PropTypes.bool,
};

export default Character;
