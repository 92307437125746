import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyB2Vzbya9sxhchN9JlUauHw-Ae2b-ZJSjI',
		authDomain: 'cgl-grill-test.firebaseapp.com',
		projectId: 'cgl-grill-test',
		storageBucket: 'cgl-grill-test.appspot.com',
		messagingSenderId: '729682277476',
		appId: '1:729682277476:web:44a35a05bf4f2ffb3b5b71'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDG3wawlLmBKzAtkyxKzGN5Adgze3lhPts',
		authDomain: 'cgl-grill-production.firebaseapp.com',
		projectId: 'cgl-grill-production',
		storageBucket: 'cgl-grill-production.appspot.com',
		messagingSenderId: '1012722658430',
		appId: '1:1012722658430:web:2a215fe36e955d9a7ffc2f'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;