import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import {spotErrorsMinusPoints} from 'data/points-data';
import {checkIfConditionsAreFulfilled} from 'helpers/effect-helper';
import TaskIntro from '../task-intro/task-intro';
import Button from 'components/ui/button/button';
import './spot-errors.scss';

const SpotErrors = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData, 
		moduleData,
		deviceInfo,
		handleInstantTaskEffects,
		handleCompleteTask
	} = props;

	var timeout = null;

	/* Check if completed already */
	let isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track available and selected options */
	const [spottedErrorIds, setSpottedErrorIds] = useState([]);
	const [numberOfMisses, setNumberOfMisses] = useState(0);

	/**
	 * Get selected option ids
	 * @returns {array} selectedOptionIds
	 */
	const getSpottedErrorIds = () => {
		let errorIds = [];
		if (playerTaskData && playerTaskData.spottedErrorIds) {
			errorIds = playerTaskData.spottedErrorIds;
		}
		return errorIds;
	};

	/* New task: update status and spotted errors */
	useEffect(() => {
		setSpottedErrorIds(getSpottedErrorIds());
		setNumberOfMisses(0);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/* Component did mount / will unmount */
	useEffect(() => {
		return () => {
			if (timeout) clearTimeout(timeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * Check if all correct items have been selected
	 * @param {array} selectedItemIds 
	 * @param {object} taskData 
	 */
	const checkIfAllCorrectItemsHaveBeenSelected = (selectedItemIds, taskData) => {
		let allCorrectItemsSelected = false;
		allCorrectItemsSelected = true;
		taskData.errors.forEach((item) => {
			if (!allCorrectItemsSelected) return;
			if (item.isCorrect === true && !selectedItemIds.includes(item.id)) {
				allCorrectItemsSelected = false;
			}
		});
	};

	/**
	 * Spot error
	 * @param {string} errorId 
	 * @returns 
	 */
	const spotErrorId = (errorId) => {
		/* Already completed */
		if (isCompleted === true) return;		

		/* Already selected */
		if (spottedErrorIds.indexOf(errorId) >= 0) return;

		/* Get item data */
		const selectedErrorData = taskData.errors.find((item) => {return item.id === errorId;});

		/* Effects */
		let instantEffects = [];

		/* Spot error */
		let newSpottedErrorIds = [...spottedErrorIds];
		if (selectedErrorData && selectedErrorData.isCorrect) {
			newSpottedErrorIds.push(errorId);
			setSpottedErrorIds(newSpottedErrorIds);
		}
		
		if (!selectedErrorData) {
			/* Update misses count */
			const newNumberOfMisses = numberOfMisses + 1;
			setNumberOfMisses(newNumberOfMisses);
		}
			
		/* Effects */
		instantEffects = [{type: 'streak', isCorrectAnswer: selectedErrorData ? selectedErrorData.isCorrect : false}];
		const spottedErrorData = taskData.errors.find((error) => {return error.id === errorId;});
		if (spottedErrorData && spottedErrorData.effects && spottedErrorData.effects.length > 0) {
			spottedErrorData.effects.forEach((effect, effectIndex) => {
				/* Effect: feedback (instant) */
				if (effect.type === 'feedback') {
					let effectObj = JSON.parse(JSON.stringify(effect));
					effectObj.feedback.moduleId = moduleData.id;
					effectObj.feedback.audioFileName = languageId + '-' + taskData.taskId 
						+ '-' + errorId + '-' + effect.type + '-' + (effectIndex + 1);

					instantEffects.push(effectObj);
				}
			});
		}

		/* Check if all correct items have been selected */
		const allCorrectItemsSelected = checkIfAllCorrectItemsHaveBeenSelected(newSpottedErrorIds, taskData);
		
		/* Check if task should be auto-completed */
		const taskIsAutoCompleted = (taskData.doneWhenAllCorrectItemsSelected && allCorrectItemsSelected);
		if (taskIsAutoCompleted) {
			/* Complete task automatically */
			completeTask(instantEffects, newSpottedErrorIds);
		} else {
			/* Instant effects */
			if (instantEffects.length > 0) handleInstantTaskEffects(instantEffects);
		}
	};

	/**
	 * Click on background
	 */
	const backgroundClick = () => {
		/* Already completed */
		if (isCompleted === true) return;

		/* Update misses count */
		const newNumberOfMisses = numberOfMisses + 1;
		setNumberOfMisses(newNumberOfMisses);
		
		/* Update streak */
		const instantEffects = [
			{type: 'streak', isCorrectAnswer: false},
			{type: 'shake-screen'}
		];
		handleInstantTaskEffects(instantEffects);
	};

	/**
	 * Complete task
	 */
	const completeTask = (instantEffects = null, newSpottedErrorIds = null) => {
		/* Prepare array of effects */
		let effects = (instantEffects ? [...instantEffects] : []);
		let finalSpottedErrorIds = (newSpottedErrorIds ? [...newSpottedErrorIds] : spottedErrorIds);
		
		/* Errors */ 
		const errors = numberOfMisses;

		/* Calculate points */
		let points = finalSpottedErrorIds.length;
		if (points > 0 && errors > 0) {
			const errorIndex = spotErrorsMinusPoints.errorLimits.findIndex((limit) => {return errors >= limit;});
			if (errorIndex >= 0) {
				const minusPoints = spotErrorsMinusPoints.pointValues[errorIndex];
				points = Math.max(spotErrorsMinusPoints.minPoints, points - minusPoints);
			}
		}

		/* Result popup */
		const numberOfErrors = taskData.errors.filter((error) => {
			return error.isCorrect;
		}).length;
		const spottedErrors = finalSpottedErrorIds.length;
		if (taskData.showDefaultResultPopup) {
			let resultTier = 1;
			if (spottedErrors / numberOfErrors > 0.333) resultTier = 2;
			if (spottedErrors / numberOfErrors > 0.667) resultTier = 3;
			const popupTitle = getText(playerUiTexts.spotErrorsPopup['title' + resultTier], languageId);
			const popupText = getText(playerUiTexts.spotErrorsPopup['text' + resultTier], languageId)
				.replace('%spottedErrors%', spottedErrors)
				.replace('%numberOfErrors%', numberOfErrors);
			effects.push({
				type: 'popup',
				popup: {type: 'spot-errors-result', title: popupTitle, text: popupText}
			});
		}

		/* Effects */
		if (taskData.doneEffects && taskData.doneEffects.length > 0) {
			taskData.doneEffects.forEach((effect) => {
				const conditionsAreFulfilled = checkIfConditionsAreFulfilled(effect, errors);
				if (conditionsAreFulfilled) {
					if (effect.type === 'points') {
						/* Effect: points */
						points += effect.value;
					} else {
						/* Effect: feedback, popup */
						effects.push(effect);
					}
				}
			});
		}
	
		/* Save completed task */
		handleCompleteTask(
			'spot-errors', 
			points, 
			errors, 
			effects,
			{spottedErrorIds: spottedErrorIds}
		);
	};

	return (
		<div className={'SpotErrors ' + taskData.layout + (isCompleted ?  ' completed' : '')}>
			<div className={'SpotErrors-errors ' + (taskData.image ? taskData.image : '')} 
				onClick={() => {backgroundClick();}}>
				{taskData.errors.map((error) => {
					return (
						<div 
							key={error.id} 
							className={'SpotErrors-error ' + error.id 
								+ ((spottedErrorIds.indexOf(error.id) >= 0 && error.isCorrect) ? ' spotted' : '')
								+ (error.isCorrect ? ' isError' : '')
							}
							onClick={(e) => {e.stopPropagation(); spotErrorId(error.id);}} 
						/>
					);
				})}
			</div>
			<div id="taskIntro" className="SpotErrors-intro">
				<TaskIntro 
					languageId={languageId}
					moduleId={moduleData.id}
					deviceInfo={deviceInfo}
					text={taskData.text ? getText(taskData.text, languageId) : null}
					image={taskData.image}
					fileName={languageId + '-' + taskData.taskId}
				/>
			</div>
			
			<div className='SpotErrors-doneBtn'>
				{(!isCompleted && !taskData.doneWhenAllErrorsSpotted) && 
					<Button
						classes={['blue']}
						text={getText(playerUiTexts.ok, languageId)}
						onClick={(e) => {e.stopPropagation(); completeTask();}}
					/>
				}
			</div>
		</div>
	);
};

SpotErrors.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default SpotErrors;
