/* Always loaded */
const basicImagesData = [
	'icons-arrow-2.svg',
	'icons-arrow-right.svg',
	'icon-arrow.svg',
	'icon-cookies.svg',
	'icon-cross.svg',
	'icon-language.svg',
	'icon-loading-white.svg',
	'icon-loading.svg',
	'icon-lock.svg',
	'icon-logout-2.svg',
	'icon-logout.svg',
	'icon-retry.svg',
	'languages/da.svg',
	'languages/en.svg',
];

/* Loaded if logged in as player */
const basicGameImagesData = [
	'characters/manager-burned.svg',
	'characters/manager.svg',
	'characters/seagull.svg',
	'icons/icon-audio-pause-blue.svg',
	'icons/icon-audio-pause.svg',
	'icons/icon-audio-play-blue.svg',
	'icons/icon-audio-play.svg',
	'icons/icon-checkmark.svg',
	'icons/icon-correct.svg',
	'icons/icon-drag.svg',
	'icons/icon-home.svg',
	'icons/icon-next.svg',
	'icons/icon-star-filled.svg',
	'icons/icon-star.svg',
	'icons/icon-wrong.svg',
];

/* Loaded if logged in as facilitator/admin */
const facilitatorImagesData = [
	'icons/icon-arrow-down.svg',
	'icons/icon-cross-white.svg',
	'icons/icon-sort-asc.svg',
	'icons/icon-sort-desc.svg',
];

const imagesData = {
	'basic': basicImagesData,
	'basic-game': basicGameImagesData,
	'facilitator': facilitatorImagesData,
	's1-m1': require('data/images/s1-m1').moduleImages,
};

export {
	imagesData
};
