import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import resetAnimation from 'helpers/animation-helper';
import './speech-bubble.scss';

const SpeechBubble = ({shouldFadeSpeech = false, type, taskId, deviceInfo, children}) => {
	useEffect(() => {
		resetAnimation('speech');
	}, [taskId]);

	return (
		<div className={
			'SpeechBubble' + 
			(type ? ' ' + type : '') + 
			(shouldFadeSpeech ? ' fade' : '') +
			' ' + deviceInfo.orientation
		}>
			{children}
		</div>
	);
};

SpeechBubble.propTypes = {
	shouldFadeSpeech: PropTypes.bool,
	type: PropTypes.string,
	taskId: PropTypes.string,
	deviceInfo: PropTypes.object.isRequired,
	children: PropTypes.any,
};

export default SpeechBubble;