import React from 'react';
import './trainingGamesBtn.scss';

const TrainingGamesBtn = () => {

	return (
		<a 
			className='TrainingGamesBtn'
			href="https://cphgamelab.dk/" 
			target="_blank" 
			rel="noreferrer"
		>
			Training Games
		</a>
	);
};

export default TrainingGamesBtn;