// NOTE: remember to update the UI-texts file on the drive, if you update this file */

const generalUiTexts = {
	grillTraining: {
		da: 'Grilltræning',
		en: 'Grill training',
	},
	start: {
		da: 'Start',
		en: 'Start',
	},
	retry: {
		da: 'Prøv igen',
		en: 'Try again'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
	},
	submit: {
		da: 'Indsend',
		en: 'Submit'
	},
	raffleTitle: {
		da: `Lodtrækning`,
		en: `Raffle`
	},
	registerNewsletter: {
		da: `Tilmeld nyhedsbrev`,
		en: `Register newsletter`
	}
};


module.exports = {
	generalUiTexts
};