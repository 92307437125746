const moduleImages = [
	'background/outside/endgame.svg',
	'background/outside/grill-blazing.svg',
	'background/outside/grill-fired.svg',
	'background/outside/grill-off.svg',
	'background/outside/grill-on.svg',
	'background/outside/outside.svg',

	'modules/tasks/multiple-choice/s1-m1-grill-clothing/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-grill-clothing/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-grill-clothing/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-grill-clothing/option-4.svg',
	
	'modules/tasks/multiple-choice/s1-m1-handwashing/background.svg',
	
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-1.svg',
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-2.svg',
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-3.svg',
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-4.svg',
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-5.svg',
	'modules/tasks/multiple-choice/s1-m1-lightning-fuel/option-6.svg',

	'modules/tasks/organize/s1-m2-grilling-amount/background.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/beef-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/beef-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/beef-welldone.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/carton-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/carton-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/carton-welldone.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/chicken-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/chicken-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/chicken-welldone.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/mince-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/mince-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/mince-welldone.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/sausage-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/sausage-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/sausage-welldone.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/squash-medium.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/squash-raw.svg',
	'modules/tasks/organize/s1-m2-grilling-amount/squash-welldone.svg',

	'modules/tasks/spot-errors/s1-m2-grill-hygiene/background.svg',
];

export {
	moduleImages
};