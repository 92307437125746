import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from 'helpers/language-helper';
import Audio from 'components/ui/audio/audio';
import './order-push-dnd-graphic.scss';

const OrderPushDndGraphic = ({itemData, isDragging, classes, languageId, audioFileName}) => {
	const [opacity, setOpacity] = useState(1);
	const [hasDragged, setHasDragged] = useState(false);
	const [shouldAnimate, setShouldAnimate] = useState(false);

	/* Class name */
	let className = 'OrderPushDndGraphic';
	if (classes && classes.length > 0) {classes.forEach((c) => {className += ' ' + c;});}

	useEffect(() => {
		/* Opacity (invisible if dragging) */
		if (isDragging) {
			setOpacity(0);
			setHasDragged(true);
		} else {
			setOpacity(1);
			// We dont want to start animating, before we have dragged
			if (hasDragged) {
				// Start animation
				setShouldAnimate(true);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDragging]);

	return (
		<div 
			className={className + (shouldAnimate ? ' animated' : '')} 
			style={{opacity}} 
			onAnimationEnd={() => {setShouldAnimate(false);}}>
			{(itemData && itemData.text) && 
				<span>
					{getText(itemData.text, languageId)}
				</span>
			}
			<div className="OrderPushDndGraphic-audio">
				<Audio 
					type='task-option'
					color='blue'
					fileName={audioFileName}
				/>
			</div>
			<div className={'OrderPushDndGraphic-handle '}/>
		</div>
	);
};

OrderPushDndGraphic.propTypes = {
	itemData: PropTypes.object.isRequired,
	isDragging: PropTypes.bool.isRequired,
	classes: PropTypes.array,
	languageId: PropTypes.string.isRequired,
	audioFileName: PropTypes.string.isRequired,
};

export default OrderPushDndGraphic;