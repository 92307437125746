import React, {Component} from 'react';
import PropTypes from 'prop-types';
import 'firebase/compat/firestore';
import {DndProvider} from 'react-dnd-multi-backend';
import {HTML5toTouch} from 'rdndmb-html5-to-touch';
import {playerDataTemplate} from 'data/templates/player-data-template';
import Game from 'components/game/game';

class PlayerController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gameStarted: false,
			page: 'game',
		};
	}

	/**
	 * Reset player game data for this game
	 */
	resetPlayerGameData = () => {
		/* Reset data in cookie */
		return this.props.updateUser(
			{...Object.assign({}, 
				playerDataTemplate
			)}
		);
	};

	/**
	 * Update player info
	 * @param {string} name 
	 * @param {string} email 
	 */
	updatePlayerInfo = (name, email) => {
		this.props.updateUser({name, email}).then(() => {
			if (this.state.page !== 'game') this.setState({page: 'game'});
		});
	};


	/**
	 * Go to page
	 * @param {string} page 
	 */
	handleGoToPage = (page) => {
		this.setState({page: page});
	};

	/**
	 * Render component
	 */
	render = () => {

		return (
			<DndProvider options={HTML5toTouch}>
				<Game 
					languageId={this.props.languageId}
					backgroundStatus={this.props.backgroundStatus}
					userData={this.props.userData}
					deviceInfo={this.props.deviceInfo}
					scrollToTop={this.props.scrollToTop}
					handleGoToPage={this.handleGoToPage}
					resetPlayerGameData={this.resetPlayerGameData}
					updateUser={this.props.updateUser}
					setCookieChoiceMade={this.props.setCookieChoiceMade}
					setBackground={this.props.setBackground}
					handleShakeScreen={this.props.handleShakeScreen}
				/>
			</DndProvider>
		);
	};
};

PlayerController.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	userData: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired,
	setCookieChoiceMade: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired
};

export default PlayerController;
