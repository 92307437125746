import appConfig from 'config/app.config';
import {
	starsPerPercentPoints, 
	multipleChoicePoints, 
	sortPoints, 
	orderPoints, 
	organizePoints,
} from 'data/points-data';
import {getAllModulesData} from './module-helper';

/**
 * Get max possible points of a module
 * @param {string} moduleId 
 * @returns 
 */
export function getModuleMaxPoints(moduleId) {
	let maxPoints = 0;

	const moduleData = getAllModulesData().find((m) => {return m.id === moduleId;});
	if (moduleData && moduleData.tasks) {
		moduleData.tasks.forEach((task) => {
			maxPoints += getTaskMaxPoints(task);
		});
	}
	return maxPoints;
};

/**
 * Get max possible points of a task
 * @param {object} task 
 * @returns 
 */
export function getTaskMaxPoints(task) {
	let maxPoints = 0;

	/* Click-to-solve (only in clock-tasks) */
	if (task.type === 'click-to-solve' && task.maxPoints) maxPoints += task.maxPoints;

	/* Multiple choice */
	if (task.type === 'multiple-choice') {
		if (task.isDilemma === true) {
			/* Dilemma */
			maxPoints += (task.maxPoints ? task.maxPoints : 0);
		} else {
			/* Not dilemma */
			maxPoints += Math.min(task.options.length, multipleChoicePoints.basePoints);
		}
	}

	/* Sort, order, paper-doll */
	if (task.type === 'sort') maxPoints += sortPoints.pointValues[0];
	if (task.type === 'order') maxPoints += orderPoints.pointValues[0];

	/* Spot-errors */
	if (task.type === 'spot-errors' && task.errors) {
		const correctErrorListLength = task.errors.filter((error) => {
			return error.isCorrect;
		}).length;
		maxPoints += correctErrorListLength;
	}

	/* Dialogue, branching-story */
	if ((task.type === 'dialogue' || task.type === 'branching-story') && task.maxPoints) {
		maxPoints += task.maxPoints;
	}

	/* Organize */
	if (task.type === 'organize') {
		if (task.rules && task.rules.length > 0) {
			maxPoints += organizePoints.pointValues[0];
		} else {
			if (task.maxPoints && task.maxPoints > 0) {
				maxPoints += task.maxPoints;
			} else if (task.doneEffects && task.doneEffects.length > 0) {
				task.doneEffects.forEach((e) => {if (e.type === 'points' && e.vaue) maxPoints += e.value;});
			}
		}
	}
	
	return maxPoints;
}

/**
 * Get number of filled stars in a module
 * @param {number} points 
 * @param {number} maxPoints 
 * @returns 
 */
export function getNumberOfFilledStars(points, maxPoints) {
	if (!points || !maxPoints) return 0;

	const percentPoints = points / maxPoints * 100.;
	let numberOfFilledStars = 0;
	starsPerPercentPoints.pointSteps.forEach((step, index) => {
		if (percentPoints >= step) numberOfFilledStars = starsPerPercentPoints.filledStars[index];
	});

	return numberOfFilledStars;
};


/**
 * Get array of filled/empty stars
 * @param {object} playerModuleData 
 * @returns 
 */
export function getModuleStars(playerModuleData) {
	let numberOfFilledStars = 0;
	if (playerModuleData) {
		const playerMaxPoints = playerModuleData.maxPoints;
		const moduleMaxPoints = getModuleMaxPoints(playerModuleData.moduleId);
		numberOfFilledStars = getNumberOfFilledStars(playerMaxPoints, moduleMaxPoints);
	}
	
	const stars = [];
	for (let i = 1; i <= appConfig.maxStarsPerModule; i++) {
		let star = (i <= numberOfFilledStars);
		stars.push(star);
	}
	return stars;
}