import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {scenariosData} from 'data/scenarios-data';
import StartGame from './start-game/start-game';
import ModuleController from './module/module-controller';
import './game.scss';

const Game = (props) => {
	const {
		languageId, 
		backgroundStatus,
		userData, 
		deviceInfo,
		scrollToTop, 
		handleGoToPage, 
		updateUser, 
		setCookieChoiceMade,
		resetPlayerGameData, 
		setBackground,
		handleShakeScreen
	} = props;

	const scenarioId = 'scenario-1';
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
	
	const initialPageId = 'start-game';
	const initialModuleId = (scenarioData.modulesData.length === 1 ? scenarioData.modulesData[0].id : null);

	/* Game page */
	const [navigation, setNavigation] = useState({
		pageId: initialPageId, 
		moduleId: initialModuleId
	});

	/**
	 * Go to game page
	 * @param {string} pageId
	 * @param {string} moduleId
	 */
	const handleGoToGamePage = (pageId, moduleId = null) => {
		if (pageId === 'modules-overview') {
			setBackground('outside', 'bottom');
		}
		setNavigation({pageId, moduleId});
	};

	/* Game page component */
	let GamePageComponent = StartGame;
	let showCookieBtn = true;
	if (navigation.pageId === 'module' && navigation.moduleId) {
		GamePageComponent = ModuleController;
		showCookieBtn = false;
	}

	return (
		<div className={'Game ' + navigation.pageId}>
			{backgroundStatus !== 'fade' && <GamePageComponent 
				languageId={languageId}
				backgroundStatus={backgroundStatus}
				moduleId={navigation.moduleId}
				userData={userData}
				scenarioId={scenarioId}
				deviceInfo={deviceInfo}
				scrollToTop={scrollToTop}
				handleGoToGamePage={handleGoToGamePage}
				handleGoToPage={handleGoToPage}
				updateUser={updateUser}
				resetPlayerGameData={resetPlayerGameData}
				setBackground={setBackground}
				handleShakeScreen={handleShakeScreen}
			/>}
			{showCookieBtn &&
				<div className='Game-cookieBtn' onClick={() => {setCookieChoiceMade(false);}}/>
			}
		</div>
	);
};

Game.propTypes = {
	languageId: PropTypes.string.isRequired,
	backgroundStatus: PropTypes.string.isRequired,
	userData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	scrollToTop: PropTypes.func.isRequired,
	handleGoToPage: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	setCookieChoiceMade: PropTypes.func.isRequired,
	resetPlayerGameData: PropTypes.func.isRequired,
	setBackground: PropTypes.func.isRequired,
	handleShakeScreen: PropTypes.func.isRequired,
};

export default Game;
