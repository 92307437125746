import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDrag, useDrop} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import './order-push-dnd-item.scss';

const OrderPushDndItem = ({
	setCurrentDraggedItemId,
	handleUnsetPreview,
	handleSwitchItems,
	handleSetPreview, 
	isDraggable, 
	itemIndex, 
	itemData,
	itemLocation,
	currentPreview,
	previewList,
}) => {
	const [opacity, setOpacity] = useState(1);
	
	/* Drag functionality */
	const [{ isDragging }, drag, preview] = useDrag({
		type: 'item',
		item: {
			type: 'item', 
			index: itemIndex, 
			id: (itemData ? itemData.id : null),
			initialLocation: itemLocation,
		},
		canDrag() {return (isDraggable);},
		isDragging(monitor) {return (itemIndex === monitor.getItem().index);},
		collect: (monitor) => {return {isDragging: monitor.isDragging()};},
	});

	/* Drop functionality */
	const [, drop] = useDrop({
		accept: 'item',
		drop() {
			if (!isDraggable) return;

			if (handleSwitchItems) {
				/* Move card */
				handleSwitchItems();
			}
			handleUnsetPreview();
		},
		hover(otherItem) {
			// OtherItem is the item of which this item is being hovered by
			if (otherItem.id === itemData.id) {
				return;
			}

			let otherItemPreviewData = null;
			
			if (previewList) {
				otherItemPreviewData = previewList.find((preview) => {
					return preview.item.id === otherItem.id;
				});
			}

			let otherItemLocation = otherItem.initialLocation;
			if (otherItemPreviewData) {
				otherItemLocation = otherItemPreviewData.location;
			}

			// We already have a preview set for this location combination
			if (
				currentPreview &&
				currentPreview.fromLocation === otherItemLocation &&
				currentPreview.toLocation === itemLocation
			) {
				return;
			}

			if (itemLocation === otherItemLocation) {
				return;
			}

			handleSetPreview(otherItem.id, otherItemLocation, itemLocation);
		}
	});

	/* Hide browser-drawn drag preview image when dragging */
	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	});

	useEffect(() => {
		if (isDragging) {
			setCurrentDraggedItemId(itemData.id);
		} else {
			setCurrentDraggedItemId(null);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDragging]);

	/* Class name */
	let className = 'OrderPushDndItem';

	const ref = useRef(null);
	const dragDropRef = drag(drop(ref));

	return (
		<div ref={dragDropRef} className={className} style={{opacity}}>
		</div>
	);
};

OrderPushDndItem.propTypes = {
	setCurrentDraggedItemId: PropTypes.func.isRequired,
	handleUnsetPreview: PropTypes.func.isRequired,
	handleSwitchItems: PropTypes.func.isRequired,
	handleSetPreview: PropTypes.func.isRequired,
	isDraggable: PropTypes.bool.isRequired,
	itemIndex: PropTypes.number.isRequired,
	itemData: PropTypes.object.isRequired,
	itemLocation: PropTypes.number.isRequired,
	currentPreview: PropTypes.object,
	previewList: PropTypes.array,
};

export default OrderPushDndItem;
