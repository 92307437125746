import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/language-helper';
import {orderPoints} from 'data/points-data';
import {shuffleArray} from 'helpers/array-helper';
import {playerUiTexts} from 'data/ui-texts/player-ui-texts';
import Button from 'components/ui/button/button';
import TaskIntro from 'components/game/module/engines/task-intro/task-intro';
import OrderDndContainer from './order-dnd-container';
import OrderDndItem from './order-dnd-item';
import OrderDndPreview from './order-dnd-preview';
import Popup from 'components/ui/popup/popup';
import './order.scss';

const Order = (props) => {
	const {
		languageId,
		playerTaskData, 
		taskData,
		moduleData,
		deviceInfo,
		updateLoggedTime,
		handleInstantTaskEffects,
		handleCompleteTask
	} = props;

	/* Timeout */
	let timeout = useRef(null);

	/* Check if completed already */
	const isCompleted = (playerTaskData && playerTaskData.isCompleted === true ? true : false);

	/* Track ordered items and round number */
	const [orderedItems, setOrderedItems] = useState([]);
	const [roundNumber, setRoundNumber] = useState(1);

	/* Popup with number of correct positions */
	const [popupData, setPopupData] = useState(null);

	/* Animate wrong items */
	const [isAnimating, setIsAnimating] = useState(false);

	/* Get items to be ordered */
	const getOrderedItems = () => {
		let items = [];

		if (playerTaskData && playerTaskData.orderedItems) {
			/* Task solved, get items from player data */	
			items = JSON.parse(JSON.stringify(playerTaskData.orderedItems));
			items.forEach((item, index) => {
				if (!item.location) item.location = 'final';
				if (!item.positionNumber) item.positionNumber = index + 1;
			});
		} else {
			/* Get items data file and shuffle */
			items = shuffleArray(taskData.items.map((item) => {return {id: item.id, isLocked: false};}));
			items.forEach((item, index) => {
				item.location = 'initial';
				item.positionNumber = index + 1;
			});
		}
		return items;
	};


	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		
		return () => {
			/* Component will unmount */
			if (timeout.current) clearTimeout(timeout.current);
		};
	}, []);

	/* Update sorted items if new task */
	useEffect(() => {
		setOrderedItems(getOrderedItems());
		setRoundNumber(1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Check if item is placed correct
	 * @param {string} itemId 
	 * @returns 
	 */
	const checkIfItemsIsPlacedCorrect = (itemId) => {
		let isPlacedCorrect = false;
		const placedItem = orderedItems.find((i) => {return (i.id === itemId);});
		if (placedItem) {
			const itemData = taskData.items.find((i) => {return i.id === placedItem.id;});
			if (
				itemData && 
				itemData.correctOrderNumbers.indexOf(placedItem.positionNumber) >= 0
			) {
				isPlacedCorrect = true;
			}
		}
		return isPlacedCorrect;
	};

	/**
	 * Switch items
	 * @param {string} itemId 
	 * @param {string} toLocation
	 * @param {number} toPositionNumber 
	 */
	const handleMoveItem = (itemId, toLocation, toPositionNumber) => {
		/* Update logged time */
		updateLoggedTime();

		/* Move / switch items */
		let newOrderedItems = JSON.parse(JSON.stringify(orderedItems));
		
		const fromIndex = orderedItems.findIndex((i) => {return i.id === itemId;});
		const toIndex = orderedItems.findIndex((i) => {
			return (i.location === toLocation && i.positionNumber === toPositionNumber);
		});

		/* Move item */
		newOrderedItems[fromIndex].location = toLocation;
		newOrderedItems[fromIndex].positionNumber = toPositionNumber;
		if (toIndex >= 0) {
			/* Switch item */
			newOrderedItems[toIndex].location = orderedItems[fromIndex].location;
			newOrderedItems[toIndex].positionNumber = orderedItems[fromIndex].positionNumber;
		}
		setOrderedItems(newOrderedItems);
	};

	/**
	 * Confirm order
	 */
	const confirmOrder = () => {
		/* Check if all items have been placed */
		if (orderedItems.some((item) => {return item.location === 'initial';})) return;
		
		/* Count errors */
		let errors = 0;
		orderedItems.forEach((item) => {
			const isPlacedCorrect = checkIfItemsIsPlacedCorrect(item.id);
			if (!isPlacedCorrect) {
				errors += 1;
			}
		});

		if (errors > 0) {
			/* Update streak */
			const instantEffects = [{type: 'streak', isCorrectAnswer: false}];
			handleInstantTaskEffects(instantEffects);

			/* Show popup */
			const popupText = getText(playerUiTexts.orderPopup.text, languageId);
			const popupTitle = getText(playerUiTexts.orderPopup.title, languageId)
				.replace('%correctAnswers%', (orderedItems.length - errors))
				.replace('%totalAnswers%', orderedItems.length);
			setPopupData({
				title: popupTitle,
				text: popupText
			});
		} else {
			/* Complete task */
			completeTask();
		}
	};

	/**
	 * Close popup
	 */
	const prepareNextRound = () => {
		/* Update round number */
		const newRoundNumber = roundNumber + 1;
		setRoundNumber(newRoundNumber);

		/* Start animation */
		setIsAnimating(true);

		/* Close popup */
		setPopupData(null);

		/* Lock correctly placed items, move wrongly placed items back */
		let newOrderedItemIds = [...orderedItems];
		newOrderedItemIds.forEach((item) => {
			if (checkIfItemsIsPlacedCorrect(item.id)) {
				
				/* Correct -> lock */
				item.isLocked = true;
			} else {
				/* Wrong -> move */
				item.location = 'initial';
			}
		});
		setOrderedItems(newOrderedItemIds);

		timeout.current = setTimeout(() => {setIsAnimating(false);}, 1500);
	};



	/**
	 * Complete task
	 */
	const completeTask = () => {
		/* Errors */ 
		const errors = (roundNumber - 1);
		
		/* Calculate points */
		let points = orderPoints.minPoints;
		let pointIndex = orderPoints.pointLimits.findIndex((limit) => {return errors <= limit;});
		if (pointIndex >= 0) points = orderPoints.pointValues[pointIndex];

		/* Save completed task */
		handleCompleteTask(
			'order', 
			points, 
			errors, 
			[{type: 'streak', isCorrectAnswer: true}],
			{orderedItems: orderedItems}
		);
	};

	return (
		<div className={'Order'}>
			<div id="taskIntro" className="Order-intro">
				<TaskIntro 
					languageId={languageId}
					moduleId={moduleData.id}
					deviceInfo={deviceInfo}
					text={taskData.text ? getText(taskData.text, languageId) : null}
					image={taskData.image}
					fileName={languageId + '-' + taskData.taskId}
				/>
			</div>

			<div className="Order-itemsWrap">
				<div className={'Order-items size-' + orderedItems.length}>
					{/* Final containers */}
					{[...Array(orderedItems.length)].map((_, index) => {
						/* Check if can be dropped into  */		
						let isDroppable = true;				
						const placedItem = orderedItems.find((i) => {
							return (i.location === 'final' && i.positionNumber === (index + 1));
						});
						if (isCompleted || isAnimating || (placedItem && placedItem.isLocked)) {
							isDroppable = false;
						} 				
						return (
							<div key={index} className={'Order-container final pos-' + (index + 1)}>
								<div className="Order-containerNumber"><span>{(index + 1)}.</span></div>
								<OrderDndContainer 
									isDroppable={isDroppable}
									layout={taskData.layout ? taskData.layout : null}
									location="final"
									positionNumber={index + 1}
									handleMoveItem={handleMoveItem}
								/>
							</div>
						);
					})}

					{/* Initial containers */}
					{[...Array(orderedItems.length)].map((_, index) => {
						let isDroppable = (isCompleted || isAnimating ? false : true);	
						return (
							<div key={index} className={'Order-container initial pos-' + (index + 1)}>
								<OrderDndContainer 
									isDroppable={isDroppable}
									layout={taskData.layout ? taskData.layout : null}
									location="initial"
									positionNumber={index + 1}
									handleMoveItem={handleMoveItem}
								/>
							</div>
						);
					})}

					{/* Items (also work as containers) */}
					{orderedItems.map((item, index) => {
						/* Check if can be dropped into or dragged */					
						let isDroppable = true;	
						let isDraggable = (item.isLocked ? false : true);		

						if (isCompleted || isAnimating) {
							isDroppable = false;
							isDraggable = false;
						} 

						/* Get placed item data (if any) */
						const itemData = taskData.items.find((i) => {return i.id === item.id;});

						/* Check if placed item should indicate if it is correct or wrong */
						let classes = [];	
						const isPlacedCorrect = checkIfItemsIsPlacedCorrect(item.id);
						if (isCompleted || isAnimating || item.isLocked) {
							if (isPlacedCorrect) {
								classes.push('correct');
							} else {
								classes.push('wrong');
							}
						} 

						return (
							<div 
								key={index} 
								className={'Order-item ' + item.location + ' pos-' + item.positionNumber
										+ (isAnimating ? ' animate' : '')
								}
							>
								<OrderDndItem 
									isDroppable={isDroppable}
									isDraggable={isDraggable}
									languageId={languageId}
									moduleId={moduleData.id}
									layout={taskData.layout ? taskData.layout : null}
									location={item.location}
									positionNumber={item.positionNumber}
									itemData={itemData}
									classes={classes}
									audioFileName={languageId + '-' + taskData.taskId + '-' + itemData.id}
									handleMoveItem={handleMoveItem}
								/>
							</div>
						);
					})}
					<OrderDndPreview languageId={languageId} itemsData={taskData.items} />
				</div>
			</div>

			{/* Done button */}
			{!isCompleted && <div className="Order-doneBtn">
				<Button
					classes={['next']}
					text={getText(playerUiTexts.ok, languageId)}
					onClick={confirmOrder}
				/>
			</div>}

			{/* Popup */}
			{popupData && <Popup 
				type="order-result"
				title={popupData.title}
				text={popupData.text}
				togglePopup={prepareNextRound}
			/>}
		</div>
	);
};

Order.propTypes = {
	languageId: PropTypes.string.isRequired,
	playerTaskData: PropTypes.object,
	taskData: PropTypes.object.isRequired,
	moduleData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	updateLoggedTime: PropTypes.func.isRequired,
	handleInstantTaskEffects: PropTypes.func.isRequired,
	handleCompleteTask: PropTypes.func.isRequired
};

export default Order;
