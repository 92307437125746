import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {renderMarkdown} from 'helpers/text-helper';
import { getModuleMaxPoints, getNumberOfFilledStars } from 'helpers/points-helper';
import {getText} from 'helpers/language-helper';
import Character from 'components/ui/character/character';
import Audio from 'components/ui/audio/audio';
import SpeechBubble from 'components/ui/speech-bubble/speech-bubble';
import './instructions.scss';

const Instructions = ({
	languageId, 
	moduleData, 
	taskData, 
	deviceInfo, 
	points, 
	shouldFadeSpeech = false, 
	shouldFadeManager = false
}) => {
	const maxPoints = getModuleMaxPoints(moduleData.id);
	const starCount = getNumberOfFilledStars(points, maxPoints);

	return (
		<div className={
			'Instructions ' + 
			(taskData.background ? taskData.background : moduleData.background) +
			' ' +
			deviceInfo.orientation
		}>
			{taskData.subtype === 'endGame' &&
				<div className={'Instructions-overlay ' + deviceInfo.orientation}>
					<div className='Instructions-stars'>
						{[...Array(appConfig.maxStarsPerModule)].map((_, index) => {

							return (
								<div
									key={index} 
									className={'Instructions-star ' + (starCount > index ? 'filled' : '')}
								/>
							);
						})}
					</div>
				</div>
			}
			<div className="Instructions-content">
				{/* Speech & audio */}
				{(taskData.title || taskData.text) && 
					<SpeechBubble 
						type="instructions" 
						taskId={taskData.id} 
						deviceInfo={deviceInfo}
						shouldFadeSpeech={shouldFadeSpeech}
					>
						{taskData.title && <div className="Instructions-title">
							{getText(taskData.title, languageId)}
						</div>}
						{taskData.text && <div className="Instructions-text">
							{renderMarkdown(getText(taskData.text, languageId))}
						</div>}
						<div className="Instructions-audio">
							<Audio 
								type='task-intro'
								color='blue'
								fileName={languageId + '-' + taskData.taskId}
							/>
						</div>
					</SpeechBubble>
				}

				{/* Characters */}
				{taskData.characters.map((characterId) => {
					return (
						<div key={characterId} 
							className={'Instructions-character ' + characterId + ' ' + deviceInfo.orientation}
						>
							<Character 
								page="instructions" 
								deviceInfo={deviceInfo}
								characterId={characterId}
								shouldFadeManager={shouldFadeManager}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Instructions.propTypes = {
	languageId: PropTypes.string.isRequired,
	moduleData: PropTypes.object.isRequired,
	taskData: PropTypes.object.isRequired,
	deviceInfo: PropTypes.object.isRequired,
	points: PropTypes.number.isRequired,
	shouldFadeSpeech: PropTypes.bool,
	shouldFadeManager: PropTypes.bool,
};

export default Instructions;
