import React, {useState} from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {getText} from 'helpers/language-helper';
import {getTextsFromModules} from 'helpers/text-to-speech-helper';
import {adminUiTexts} from 'data/ui-texts/admin-ui-texts';
import {scenariosData} from 'data/scenarios-data';
import Select from 'components/ui/select/select';
import Button from 'components/ui/button/button';
import './admin-text-to-speech-popup.scss';

const AdminTextToSpeechPopup = ({languageId, togglePopup}) => {
	/* Uploading status and feedback */
	const [isUploading, setIsUploading] = useState(false);
	const [feedback, setFeedback] = useState(null);

	/* Config options */
	const languageOptions = [
		{
			id: 'da', languageCode: 'da-DK', 
			title: {da: 'Dansk', en: 'Dansk'}
		},
		{
			id: 'en', languageCode: 'en-GB', 
			title: {da: 'English (GB)', en: 'English (GB)'}
		},
	];

	// Language options
	const [selectedLanguageId, setSelectedLanguageId] = useState(languageOptions[0].id);

	/* Voice options */
	const allVoiceOptions = [
		{
			id: 'da-DK-Standard-C', 
			languageId: 'da',  
			title: {
				da: 'da-DK-Standard-C (male)', 
				en: 'da-DK-Standard-C (male)',
			}},
		{
			id: 'en-GB-Neural2-B', 
			languageId: 'en', 
			title: {
				da: 'en-GB-Neural2-B (male)', 
				en: 'en-GB-Neural2-B (male)',
			}
		},
	];
	
	/* Selected config */
	const currentVoiceOptions = allVoiceOptions.filter((o) => {return o.languageId === selectedLanguageId;});
	const [selectedVoiceId, setSelectedVoiceId] = useState(currentVoiceOptions[0].id);

	const switchLanguage = (languageId) => {
		if (languageId !== selectedLanguageId) {
			const newCurrentVoiceOptions = allVoiceOptions.filter((o) => {return o.languageId === languageId;});
			if (newCurrentVoiceOptions.length > 0) {
				setSelectedLanguageId(languageId);
				setSelectedVoiceId(newCurrentVoiceOptions[0].id);
			}	
		}
	};

	/* Selected modules */ 
	const initialSelectedModuleIds = [];
	scenariosData.forEach((s) => {
		s.modulesData.forEach((m) => {
			initialSelectedModuleIds.push(m.id);
		});
	});
	const [selectedModuleIds, setSelectedModuleIds] = useState(initialSelectedModuleIds);

	/* Toggle module id */
	const toggleModuleId = (moduleId) => {
		setFeedback(null);

		const newSelectedModuleIds = JSON.parse(JSON.stringify(selectedModuleIds));
		const moduleIndex = newSelectedModuleIds.indexOf(moduleId);
		if (moduleIndex >= 0) {
			newSelectedModuleIds.splice(moduleIndex, 1);
		} else {
			newSelectedModuleIds.push(moduleId);
		}
		setSelectedModuleIds(newSelectedModuleIds);
	};

	/* Upload texts */
	const uploadTexts = () => {
		setFeedback(null);

		if (isUploading) return;

		setIsUploading(true);

		const selectedLanguageData = languageOptions.find((o) => {return o.id === selectedLanguageId;});
		if (!selectedLanguageData) return;

		
		const texts = getTextsFromModules(selectedModuleIds, selectedLanguageId);
		if (texts.length > 0) {
			const promises1 = [];
			const promises2 = [];
			const db = firebase.firestore();
			texts.forEach((text) => {
				promises2.push((db.collection('text2speech').doc(text.id).delete()));
				promises1.push(db.collection('text2speech').doc(text.id).set({
					text: text.text,
					languageCode: selectedLanguageData.languageCode,
					audioEncoding: 'MP3',
					voiceName: selectedVoiceId
				}));
			});	
			Promise.all(promises2).then(() => {
				Promise.all(promises1).then(() => {
					setIsUploading(false);
				}).catch((error) => {
					setFeedback(error.message);
					setIsUploading(false);
				});
			}).catch((error) => {
				setFeedback(error.message);
				setIsUploading(false);
			});
			
		} else {
			setFeedback('no texts uploaded');
			setIsUploading(false);
		}
	};

	return (
		<div className="AdminTextToSpeechPopup" onClick={() => {togglePopup(null);}}>
			<div className="AdminTextToSpeechPopup-content" onClick={(e) => {e.stopPropagation();}}>
				{/* Popup header */}
				<div className="AdminTextToSpeechPopup-header">
					<div className="AdminTextToSpeechPopup-title">
						<span>{getText(adminUiTexts.textToSpeechPopup.title, languageId)}</span>
					</div>
					<div className="AdminTextToSpeechPopup-closeBtn" onClick={() => {togglePopup();}}/>
				</div>

				{/* Popup body */}
				<div className="AdminTextToSpeechPopup-body">
					<div className="AdminTextToSpeechPopup-config">
						<div className="AdminTextToSpeechPopup-configTitle">
							<span>{getText(adminUiTexts.textToSpeechPopup.configuration, languageId)}</span>
						</div>
						<div className="AdminTextToSpeechPopup-configWrap">
							<div className="AdminTextToSpeechPopup-configLanguage">
								<span>{getText(adminUiTexts.textToSpeechPopup.language, languageId)}</span>
								<Select 
									isDisabled={false}
									languageId={languageId}
									type="textToSpeech"
									defaultId={languageOptions[0].id}
									selectedId={selectedLanguageId}
									options={languageOptions}
									onSelect={switchLanguage}
								/>
							</div>
							<div className="AdminTextToSpeechPopup-configVoice">
								<span>{getText(adminUiTexts.textToSpeechPopup.voice, languageId)}</span>
								<a href="https://cloud.google.com/text-to-speech/docs/voices" target="_blank" rel="noreferrer">(?)</a>								
								<Select 
									isDisabled={false}
									languageId={languageId}
									type="textToSpeech"
									defaultId={currentVoiceOptions[0].id}
									selectedId={selectedVoiceId}
									options={currentVoiceOptions}
									onSelect={setSelectedVoiceId}
								/>
							</div>
						</div>
					</div>

					
					<div className="AdminTextToSpeechPopup-modules">
						<div className="AdminTextToSpeechPopup-modulesTitle">
							<span>{getText(adminUiTexts.textToSpeechPopup.modules, languageId)}</span>
						</div>
						{scenariosData.map((scenarioData) => {
							return (
								scenarioData.modulesData.map((moduleData) => {
									const isSelected = selectedModuleIds.includes(moduleData.id);
									return (
										<div 
											key={moduleData.id} 
											className={'AdminTextToSpeechPopup-module' 
														+ (isSelected ? ' selected' : '')}
											onClick={() => {toggleModuleId(moduleData.id);}}
										>
											<div className="AdminTextToSpeechPopup-moduleTitle">
												<span>
													{getText(scenarioData.title, languageId) + ': '}
													{getText(moduleData.title, languageId)}
												</span>
											</div>
										</div>
									);
								})
							);
						})}
					</div>
					
					<div className="AdminTextToSpeechPopup-uploadBtn">
						<Button 
							isLoading={isUploading}
							text={getText(adminUiTexts.textToSpeechPopup.uploadBtn, languageId)}
							classes={['facilitator']} 
							onClick={() => {uploadTexts();}}
						/>
					</div>

					<div className="AdminTextToSpeechPopup-feedback"><span>{feedback}</span></div>
				</div>
			</div>
		</div>
	);
};

AdminTextToSpeechPopup.propTypes = {
	languageId: PropTypes.string.isRequired,
	togglePopup: PropTypes.func.isRequired,
};

export default AdminTextToSpeechPopup;
