import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getText} from 'helpers/language-helper';
import {renderMarkdown} from 'helpers/text-helper';
import {setCookie, deleteCookie} from 'helpers/cookie-helper';
import {cookiesUiTexts} from 'data/ui-texts/cookies-ui-texts';
import Button from 'components/ui/button/button';
import './cookie-consent.scss';

const CookieConsent = ({languageId, setCookieChoiceMade}) => {
	/* Accept cookies */
	const handleAcceptCookies = () => {
		setCookie(appConfig.cookiesAcceptedCookieName, 'ok');
		setCookieChoiceMade(true);
	};

	/* Reject cookies */
	const handleRejectCookies = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		deleteCookie(appConfig.gameDataCookieName);

		setCookieChoiceMade(true);
	};

	return (
		<div className="CookieConsent">
			<div className="CookieConsent-popup">
				<div 
					className="CookieConsent-content" 
					onClick={(e) => {e.stopPropagation();}}
				>
					<div className="CookieConsent-contentWrap">
						<div className="CookieConsent-title">
							<span>{getText(cookiesUiTexts.title, languageId)}</span>
						</div>
						{<div className="CookieConsent-text">
							{renderMarkdown(getText(cookiesUiTexts.text, languageId))}
						</div>}
						<div className="CookieConsent-buttons">
							<div className="CookieConsent-button">
								<Button 
									classes={['cookies-popup', 'red', 'fullWidth']}
									text={getText(cookiesUiTexts.rejectBtn, languageId)}
									onClick={() => {handleRejectCookies();}}
								/>
							</div>
							<div className="CookieConsent-button">
								<Button 
									classes={['cookies-popup', 'green', 'fullWidth']}
									text={getText(cookiesUiTexts.okBtn, languageId)}
									onClick={() => {handleAcceptCookies();}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

CookieConsent.propTypes = {
	languageId: PropTypes.string.isRequired,
	setCookieChoiceMade: PropTypes.func.isRequired,
};

export default CookieConsent;
