const backgroundsData = [
	{
		id: 'outside',
		modifiers: [
			{
				id: 'top',
			},
			{
				id: 'bottom'
			},
			{
				id: 'grill-blazing'
			},
			{
				id: 'grill-fired'
			},
			{
				id: 'grill-off'
			},
			{
				id: 'grill-on'
			},
			{
				id: 'grill-on-center'
			},
			{
				id: 'grill-off-center'
			},
			{
				id: 'no-grill',
			}
		],
	},
];

export {backgroundsData};