import {useEffect, useRef, useState} from 'react';
import firebaseInit from 'firebase-init';
import appConfig from 'config/app.config';
import {getOrientation, getAspectRatio, checkIfInStandaloneMode} from 'helpers/device-helper';
import { getCookie, setCookie } from 'helpers/cookie-helper';
import { backgroundsData } from 'data/backgrounds-data';
import { playerDataTemplate } from 'data/templates/player-data-template';
import PlayerController from './users/player-controller';
import LoginController from './login/login-controller';
import CookieConsent from './ui/cookie-consent/cookie-consent';
import ImageLoader from './ui/image-loader/image-loader';
import Background from './ui/background/background';
import Loading from './loading/loading';
import { getGameUrl } from 'helpers/game-helper';
import './app.scss';

function App() {
	/* Device info */
	const [deviceInfo, setDeviceInfo] = useState(() => {
		return {
			isInStandaloneMode: checkIfInStandaloneMode(),
			isUsingTouch: false,
			orientation: getOrientation(),
			aspectRatio: getAspectRatio()
		};
	});

	const languageId = 'da';

	const [isLoading, setIsLoading] = useState(true);
	const [backgroundModifier, setBackgroundModifier] = useState('bottom');
	const [backgroundId, setBackgroundId] = useState('outside');
	const [backgroundStatus, setBackgroundStatus] = useState('show');

	/* COOKIES DATA */
	const [cookieChoiceMade, setCookieChoiceMade] = useState(false);
	const [playerData, setPlayerData] = useState(playerDataTemplate);

	/* SCREEN ANIMATION */
	const [shakeScreen, setShakeScreen] = useState(false);
	const timeout = useRef(null);

	/**
	 * User resized window, update orientation and aspect ratio
	 */
	const handleWindowResize = () => {
		let newDeviceInfo = JSON.parse(JSON.stringify(deviceInfo));
		newDeviceInfo.orientation = getOrientation();
		newDeviceInfo.aspectRatio = getAspectRatio();
		setDeviceInfo(newDeviceInfo);
	};
	
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */
		/* Initialize firebase */
		firebaseInit();

		const cookieData = getCookie(appConfig.gameDataCookieName);
		if (cookieData && cookieData.length > 0) {
			const parsedData = JSON.parse(cookieData);
			setPlayerData(parsedData);
		}

		/* Check cookie consent */
		const cookiesAcceptedCookie = getCookie(appConfig.cookiesAcceptedCookieName);
		if (cookiesAcceptedCookie && cookiesAcceptedCookie.length > 0) {
			setCookieChoiceMade(true);
		}

		setIsLoading(false);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Adding eventlistener for resize
	 */
	useEffect(() => {
		/* Add event listener for window size */
		if (document.addEventListener) window.addEventListener('resize', handleWindowResize, false);

		/* Component will ummount */
		return () => {
			if (document.removeEventListener) window.removeEventListener('resize', handleWindowResize, false);
		};
	});

	/**
	 * Update user (player) data
	 * @param {object} updates 
	 * @returns 
	 */
	const updateUser = (updates) => {
		/* Nothing to update */
		if (Object.keys(updates).length === 0 && updates.constructor === Object) {
			return new Promise((resolve)=>{resolve();});
		}

		return new Promise((resolve) => {
			/* Update state */
			const newPlayerData = Object.assign({}, {...playerData}, {...updates});
			setPlayerData(newPlayerData);

			// Check for cookie accept
			const acceptCookie = getCookie(appConfig.cookiesAcceptedCookieName);
			if (acceptCookie && acceptCookie.length > 0) {
				/* Update cookie */
				const newPlayerDataStr = JSON.stringify(newPlayerData);
				setCookie(appConfig.gameDataCookieName, newPlayerDataStr, 7);
			}

			resolve({status: 'success', playerData: newPlayerData});
		});
	};

	/**
	 * Switch background
	 * @param {string} newBackgroundId 
	 * @param {string} newBackgroundModifier 
	 */
	const setBackground = (newBackgroundId, newBackgroundModifier = null) => {
		if (
			newBackgroundId !== backgroundId || 
			newBackgroundModifier !== backgroundModifier
		) {
			/* Get background data */
			const backgroundData = backgroundsData.find((b) => {return b.id === newBackgroundId;});
			if (backgroundData) {
				/* Check position id */
				let newNewBackgroundModifier = newBackgroundModifier;
				if (
					backgroundData.modifiers.length > 0 &&
					(
						!newBackgroundModifier || 
						!backgroundData.modifiers.some((p) => {return p.id === newNewBackgroundModifier;})
					)
				) {
					/* Position required, but no position id or invalid position id provided */
					newNewBackgroundModifier = backgroundData.modifiers[0].id;
				}

				if (
					newBackgroundId !== backgroundId ||
					newNewBackgroundModifier !== backgroundModifier
				) {
					let newBackgroundStatus = (newBackgroundId === backgroundId ? 'pan' : 'fade');
					if (
						(backgroundModifier && backgroundModifier.includes('zoom')) || 
						(newNewBackgroundModifier && newNewBackgroundModifier.includes('zoom'))
					) {
						newBackgroundStatus = 'zoom';
					}

					setBackgroundModifier(newNewBackgroundModifier);
					setBackgroundId(newBackgroundId);
					setBackgroundStatus(newBackgroundStatus);
				}
			}
		}
	};

	/**
	 * Update background status (show, pan, fade, zoom)
	 * @param {string} status 
	 */
	const updateBackgroundStatus = (status) => {
		setBackgroundStatus(status);
	};

	/**
	 * Shake background
	 */
	const handleShakeScreen = () => {
		setShakeScreen(true);
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {setShakeScreen(false);}, 500);
	};

	/**
	 * Scroll to top of page
	 */
	const scrollToTop = () => {
		const AppDiv = document.getElementById('App');
		if (AppDiv) AppDiv.scrollTop = 0;
	};

	const gameUrl = getGameUrl(window.location.pathname);
	if (gameUrl === appConfig.faclitatorSectionUri) {
		return (
			<LoginController scrollToTop={scrollToTop} deviceInfo={deviceInfo} languageId={'da'} />
		);
	}

	if (isLoading) {
		return (
			<Loading languageId={languageId}/>
		);
	}

	return (
		<div id="App" className={'App ' + (shakeScreen ? ' shake' : '')}>
			<Background
				bgLanguageId={languageId}
				backgroundModifier={backgroundModifier}
				backgroundId={backgroundId}
				backgroundStatus={backgroundStatus}
				updateBackgroundStatus={updateBackgroundStatus}
				deviceInfo={deviceInfo}
			/>
			<PlayerController
				languageId={languageId}
				backgroundStatus={backgroundStatus}
				deviceInfo={deviceInfo}
				scrollToTop={scrollToTop}
				setBackground={setBackground}
				handleShakeScreen={handleShakeScreen}
				updateUser={updateUser}
				setCookieChoiceMade={setCookieChoiceMade}
				userData={playerData}
			/>
			<ImageLoader type="basic-game" />
			{!cookieChoiceMade &&
				<CookieConsent languageId={languageId} page={'front'} setCookieChoiceMade={setCookieChoiceMade}/>
			}
		</div>
	);
}

export default App;
