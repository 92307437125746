import {getPlayerModuleSessionIndex} from 'helpers/module-helper';
import {scenariosData} from 'data/scenarios-data';

/**
 * Check if a task should be skipped
 * @param {string} taskId 
 * @param {string} moduleId 
 * @param {string} scenarioId 
 * @param {object} playerData 
 * @returns 
 */
const checkIfSkipTaskConditionsAreMet = (taskId, moduleId, scenarioId, playerData) => {
	let skipTaskConditionsAreMet = false;

	/* Get task data */
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
	const moduleData = scenarioData.modulesData.find((m) => {return m.id === moduleId;});
	const taskData = (moduleData 
		? moduleData.tasks.find((task) => {return task.id === taskId;})
		: null
	);
	if (taskData && taskData.skipTaskConditions && taskData.skipTaskConditions.length > 0) {
		skipTaskConditionsAreMet = true;
		taskData.skipTaskConditions.forEach((condition) => {
			if (!skipTaskConditionsAreMet) return;
			if (condition.type === 'not-first-module-session') {
				const sessionIndex = getPlayerModuleSessionIndex(playerData, moduleId);
				if (sessionIndex === 0) skipTaskConditionsAreMet = false;
			}
		});
	}

	return skipTaskConditionsAreMet;
};

/**
 * Get id of next task in module
 * @param {string} taskId 
 * @param {string} moduleId 
 * @param {string} scenarioId 
 * @returns 
 */
const getNextTaskId = (taskId, moduleId, scenarioId) => {
	let nextTaskId = null;

	/* Get tasks data */
	const scenarioData = scenariosData.find((sc) => {return sc.id === scenarioId;});
	const moduleData = scenarioData.modulesData.find((m) => {return m.id === moduleId;});
	const tasksData = (moduleData && moduleData.tasks ? moduleData.tasks : []);

	/* Get current task index */
	const taskIndex = tasksData.findIndex((t) => {return t.id === taskId;});
	if (taskIndex >= 0 && (taskIndex + 1) < tasksData.length) {
		nextTaskId = tasksData[taskIndex + 1].id;
	}

	return nextTaskId;
};

export {
	checkIfSkipTaskConditionsAreMet,
	getNextTaskId
};
