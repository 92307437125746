const endpageData = {
	text1: `Vil du høre mere om at bruge spil til at træne hygiejne, sikkerhed, ledelse eller andet?
		<br /><br />
		Så skriv til<br />
		<b>info@cphgamelab.dk</b>
		<br />eller ring på <b>7070 2227</b>`,
	text2: `Vil du bare gerne være med i lodtrækningen om en Morsø Gasgrill,`,
	linkText: `så tryk her.`
};

export {
	endpageData
};