const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		background: 'outside',
		backgroundModifier: 'grill-off',
		character: ['manager pose-1'],
		text: {
			da: `Velkommen til spillet. Jeg er din grillmester og hjælper dig undervejs. Lav så få fejl i opgaverne som muligt, og se om du kan få tre stjerner.`,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-handwashing',
		layout: 's1-m1-handwashing',
		background: 'outside',
		backgroundModifier: 'grill-off-center',
		subtype: 'images',
		text: {
			da: `Inden du laver mad, skal du altid vaske hænder. Hvilke områder er nemme at glemme, når du vasker hænder. `,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Håndfladen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Håndryggen
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Neglene
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Håndled
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Mellem fingre
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Tommel
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Lillefinger
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-grill-clothing',
		layout: 's1-m1-grill-clothing',
		background: 'outside',
		backgroundModifier: 'grill-off-center',
		subtype: 'images',
		text: {
			da: `Hvad er den korrekte påklædning når du står ved grillen?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Kiss the cook-forklæde
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop! Og du kan selvfølgelig også vælge et forklæde med en anden sjov tekst.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Skudsikker vest
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Branddragt
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Den får du forhåbentlig ikke brug for`,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Bar mave
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-start-grill',
		background: 'outside',
		backgroundModifier: 'grill-off',
		character: ['manager pose-1'],
		text: {
			da: `Okay, lad os få gang i grillen. `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-lighting-fuel',
		layout: 's1-m1-lighting-fuel',
		background: 'outside',
		backgroundModifier: 'grill-off-center',
		subtype: 'images',
		text: {
			da: `Hvad må du ALDRIG bruge til at tænde op med?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Benzin
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'manager-burned',
							text: {
								da: `Netop! For så ender du med at se sådan her ud!`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Sprit
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Tændvæske
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Optændingsblokke
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Avispapir
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Danskvand
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Du MÅ gerne tænde op med danskvand. Men det bliver nok en kedelig grillaften.`,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-start-grill',
		background: 'outside',
		backgroundModifier: 'grill-blazing',
		character: ['manager pose-1'],
		text: {
			da: `Så er der ild i kullene. Godt arbejde! `,
			en: ``
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m2-spot-errors-grill-hygiene',
		layout: 's1-m2-grill-hygiene',
		background: 'outside',
		backgroundModifier: 'no-grill',
		text: {
			da: `God hygiejne er ekstra vigtigt ved grillen. Se om du kan spotte de tre problemer på det her billede. Tryk OK, når du ikke kan finde flere.`,
			en: ``,
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'raw-meat-bread', // Råt kød og brød på samme spækbræt
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'raw-meat-done-meat', // Råt kød og færdigt kød på samme tallerken
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'unclean-utencils', // Løfter færdigt kød med tang med kødsaft på
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Godt set! Brug rene redskaber til det færdige kød.`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'seagull', // Måge
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							characterId: 'seagull',
							text: {
								da: `Skuaark skuaark!`,
								en: ``,
							}
						})
					})
				],
				isCorrect: false,
			}),
		]
	}),
	Object.assign({}, taskTemplates.organizeTemplate.mainTemplate, {
		taskId: 's1-m2-organize-grilling-amount',
		layout: 's1-m2-grilling-amount',
		background: 'outside',
		backgroundModifier: 'bottom',
		showSolutionWhenDone: true,
		text: {
			da: `Hvordan skal forskellige typer mad steges? Sorter følgende i de rigtige kasser. Tryk så OK, når du er klar. `,
			en: ``
		},
		items: [
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 1, // Kylling A
				containerId: 'd1',
				image: 'chicken',
				alt: 'Kylling',
				correctContainerIds: ['a1', 'a2', 'a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 2, // Medisterpølse A
				containerId: 'd2', 
				image: 'sausage', 
				alt: 'Medisterpølse',
				correctContainerIds: ['a1', 'a2', 'a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 3, // Hakkebøffer A
				containerId: 'd3', 
				image: 'mince', 
				alt: 'Hakkebøffer',
				correctContainerIds: ['a1', 'a2', 'a3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 4, // Bøf B
				containerId: 'd4', 
				image: 'beef',
				alt: 'Bøf',
				correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 5, // Squash B
				containerId: 'd5', 
				image: 'squash', 
				alt: 'Squash',
				correctContainerIds: ['b1', 'b2', 'b3']
			}),
			Object.assign({}, taskTemplates.organizeTemplate.itemTemplate, {
				id: 6, // Koldskål C
				containerId: 'd6', 
				image: 'carton', 
				alt: 'Koldskål',
				correctContainerIds: ['c1', 'c2', 'c3']
			}),
		],
		mainContainers: [
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'well-done', // Skal gennemsteges A
				title: {
					da: `Skal gennemsteges`,
					en: ``
				},
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a1', type: 'final', finalItemId: 1}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a2', type: 'final', finalItemId: 2}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'a3', type: 'final', finalItemId: 3}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'medium', // Behøver ikke gennemsteges B
				title: {
					da: `Behøver ikke gennemsteges`,
					en: ``
				},
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b1', type: 'final', finalItemId: 4}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b2', type: 'final', finalItemId: 5}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'b3', type: 'final'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'ungrilled', // Skal ikke grilles C
				title: {
					da: `Skal ikke grilles`,
					en: ``
				},
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c1', type: 'final', finalItemId: 6}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c2', type: 'final'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'c3', type: 'final'}),
				]
			}),
			Object.assign({}, taskTemplates.organizeTemplate.mainContainerTemplate, {
				id: 'initial',
				containers: [
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd1', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd2', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd3', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd4', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd5', type: 'initial'}),
					Object.assign({}, taskTemplates.organizeTemplate.containerTemplate, {id: 'd6', type: 'initial'})
				]
			}),
		],
		doneConditions: [
			Object.assign({}, taskTemplates.organizeTemplate.doneConditionTemplate, {
				type: 'empty-main-container', mainContainerId: 'initial'
			})
		],
		rules: [
			{type: 'items-in-correct-containers'},
		],
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m2-order-different-food-types-cooking-how',
		background: 'outside',
		backgroundModifier: 'grill-fired',
		text: {
			da: `Maden er næsten færdig, men du skal lige på toilettet inden du tager den af grillen. Sæt handlingerne i den rigtige rækkefølge. Tryk så OK. `,
			en: ``,
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					da: `Gå ud på toilettet`,
					en: ``,
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					da: `Gør din ting`,
					en: ``,
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					da: `Vask hænder med sæbe`,
					en: ``,
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					da: `Tør hænder`,
					en: ``,
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					da: `Gå ud igen`,
					en: ``,
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-only-one-thing',
		background: 'outside',
		backgroundModifier: 'grill-on-center',
		text: {
			da: `Så mangler vi vist kun én ting. Hvis du vil være en ægte grillmester, hvad skal du så altid huske at sige, når al maden er spist?`,
			en: ``
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					da: `Tak for mad`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nej nej, ikke hvis du har lavet maden.`,
								en: ``,
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					da: `NU er kullene perfekte!`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Netop!`,
								en: ``,
							}
						})
					})
				],
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					da: `Hakuna matata`,
					en: ``
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					da: `Der var KUL på den mad`,
					en: ``
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								da: `Nu handlede det ikke om hvad den bedste far-joke ville være. Prøv igen.`,
								en: ``,
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-done',
		background: 'outside',
		backgroundModifier: 'grill-on',
		showProgressBar: false,
		subtype: 'endGame',
		character: ['manager pose-1'],
		text: {
			da: `Så er du igennem spillet! Husk at god hygiejne ved grillen kan spare dig en ubehagelig tur på tønden. God sommer!`,
			en: ``
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};