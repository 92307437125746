const modulesData = [
	{
		id: 's1-m1',
		background: 'outside',
		title: {
			da: 'Grilltræning',
			en: '',
		},
		minStars: 3,
		requiredModuleIds: [],
		tasks: require('./modules/module-1').moduleTasks,
	},
]

const moduleGroups = [
	{
		id: 's1-mg1',
		courseNumber: 'xxxxx',
		title: {
			da: 'Grilltræning',
			en: '',
		},
		moduleIds: [
			's1-m1'
		]
	},
];

export {
	modulesData,
	moduleGroups
};